import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, signInWithCustomToken, getIdTokenResult } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator, doc, getDoc } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import EXIF from 'exif-js';
import { VERSION } from './version';

// Your web app's Firebase configuration
const region = 'europe-west2';
const firebaseConfig = {
    apiKey: "AIzaSyDtJizmrSdvaIanHswRFocZjDr_yjweHWI",
    authDomain: "onda-v02.firebaseapp.com",
    projectId: "onda-v02",
    storageBucket: "onda-v02.appspot.com",
    messagingSenderId: "454322189765",
    appId: "1:454322189765:web:bcd259010bd0734c0147ac"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, region);
const storage = getStorage(app);

const queryParams = new URLSearchParams(window.location.search);
const isDebug = queryParams.get('debug') === 'true';
if (isDebug) {
    console.log(">>>>> Running Firestore in emulator mode");
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectStorageEmulator(storage, 'localhost', 9199);
}

// settings
const memory = navigator.deviceMemory;
console.log(`This device has at least ${memory}GiB of RAM.`);
const maxParallelUploads = (memory < 2) ? 1 : ((memory > 4) ? 3 : 2);
const compressionOptions = {
    maxSizeMB: 5,
    useWebWorker: true,
};

const UploadPage = () => {
    const [files, setFiles] = useState([]);
    const [progress, setProgress] = useState({});
    const [isUploading, setIsUploading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [error, setError] = useState(null);
    const [albumId, setAlbumId] = useState(null);
    const [templateId, setTemplateId] = useState(null);
    const [albumDetails, setAlbumDetails] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const authToken = queryParams.get('authToken');
        const albumId = queryParams.get('albumId');
        const templateId = queryParams.get('templateId');

        if (authToken) {
            signInWithCustomToken(auth, authToken)
                .then((userCredential) => {
                    // User is signed in
                    const user = userCredential.user;

                    if (!user) {
                        console.error("User not authenticated");
                        setError("Session expired");
                        return;
                    }

                    // Get the ID token result, which includes the claims
                    return getIdTokenResult(user);
                })
                .then((idTokenResult) => {
                    // The claims are available in the idTokenResult.claims object
                    const albumIdToken = idTokenResult.claims.albumId;

                    if (!albumIdToken) {
                        console.error("Album ID is missing in the token");
                        setError("Album ID is missing in the token");
                        return;
                    }

                    if (albumIdToken !== albumId) {
                        console.error("Album ID mismatch");
                        setError("Album ID mismatch");
                        return;
                    }
                }).catch((error) => {
                    console.error("Authentication error:", error);
                    setError("Authentication error");
                });
        }
        else {
            console.error("Auth Token is required");
            setError("Auth Token is required");
            return;
        }

        if (!albumId || !templateId) {
            console.error("Album ID and Template ID are required");
            setError("Album ID and Template ID are required");
            return;
        }

        setAlbumId(albumId);
        setTemplateId(templateId);

        // Fetch album details from media_templates
        const fetchAlbumDetails = async () => {
            try {
                const templateDoc = await getDoc(doc(db, 'media_templates', templateId));
                if (templateDoc.exists()) {
                    const data = templateDoc.data();
                    setAlbumDetails({
                        name: data.album.name,
                        creationTime: data.album.creation_time.toDate(), // Convert Firestore timestamp to Date
                        price: {
                            cents: data.price.cents,
                            currency: data.price.currency
                        }
                    });
                }
            } catch (err) {
                console.error("Error fetching album details:", err);
            }
        };

        fetchAlbumDetails();
    }, []);

    useEffect(() => {
        if (files.length > 0 && !isDone) {
            document.title = `${files.length} pic${files.length > 1 ? 's' : ''} are uploading...`;
        } else if (isDone) {
            document.title = `Done (${files.length} pic${files.length > 1 ? 's' : ''})`;
        }
    }, [files.length, isDone]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isUploading) {
                event.preventDefault();
                event.returnValue = ''; // This is required for Chrome to show the alert dialog
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isUploading]);

    const handleFileSelection = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const filteredFiles = selectedFiles.filter(file => allowedTypes.includes(file.type));
        setProgress({});
        setFiles(filteredFiles);
        handleFilesUpload(filteredFiles);
    };

    const handleSingleFileUpload = async (file, progressUpdate) => {
        progressUpdate(10);
        const compressedFileBlob = await imageCompression(file, compressionOptions);
        progressUpdate(30);

        //random crash
        /* if( Math.random() < 0.1) {
            throw new Error('Random crash');
        } */

        function extractImportantExif(exifData) {
            return {
                Make: exifData.Make,
                Model: exifData.Model,
                DateTime: exifData.DateTime,
                ExposureTime: exifData.ExposureTime,
                FNumber: exifData.FNumber,
                ISO: exifData.ISOSpeedRatings || exifData.ISO,
                FocalLength: exifData.FocalLength,
                LensModel: exifData.LensModel || exifData.Lens,
                Flash: exifData.Flash,
                GPSLatitude: exifData.GPSLatitude,
                GPSLongitude: exifData.GPSLongitude
            };
        }

        await new Promise((resolve) => {
            EXIF.getData(file, function () {
                compressedFileBlob.exifData = EXIF.getAllTags(this);
                resolve();
            });
        });

        progressUpdate(50);

        console.log('Exif data:', compressedFileBlob.exifData);
        const metadata = {
            contentType: compressedFileBlob.type,
            filename: file.name,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            exifData: extractImportantExif(compressedFileBlob.exifData),
        };

        const compressedFileBase64 = await blobToBase64(compressedFileBlob);
        progressUpdate(55);

        const payload = {
            file: compressedFileBase64,
            metadata: metadata,
            albumId: albumId,
            templateId: templateId,
        };

        const callableReturnMessage = httpsCallable(functions, 'uploadMedia');
        const response = await callableReturnMessage(payload);

        if (response.data.status !== 200) {
            throw new Error('Network response was not ok');
        }

        progressUpdate(100);
        return true;
    };

    const handleFilesUpload = async (files) => {
        const user = auth.currentUser;
        if (!user) {
            console.error("User not authenticated");
            setError("Session expired");
            return;
        }

        setIsUploading(true);
        let activeUploads = 0;
        let nextFileIndex = 0;

        const startNextUpload = async () => {
            if (nextFileIndex >= files.length) {
                if (activeUploads === 0) {
                    setIsUploading(false);
                    setIsDone(true);
                }
                return;
            }

            const currentIndex = nextFileIndex;
            nextFileIndex++;
            activeUploads++;

            try {
                await handleSingleFileUpload(files[currentIndex], (progressValue) => {
                    setProgress(prevProgress => ({ ...prevProgress, [currentIndex]: progressValue }));
                });
            } catch (error) {
                if (error.code === 'functions/already-exists') {
                    setProgress(prevProgress => ({ ...prevProgress, [currentIndex]: -2 }));
                }
                else {
                    console.error('Error during file upload:', error);
                    setProgress(prevProgress => ({ ...prevProgress, [currentIndex]: -1 }));
                }
            } finally {
                activeUploads--;
                startNextUpload();
            }
        };

        // Start initial batch of uploads
        for (let i = 0; i < maxParallelUploads; i++) {
            startNextUpload();
        }
    };



    const handleRetry = async (index) => {
        // reset the progress
        setProgress(prevProgress => ({ ...prevProgress, [index]: 0 }));
        // retry
        await handleSingleFileUpload(files[index], (progressValue) => {
            setProgress(prevProgress => ({ ...prevProgress, [index]: progressValue }));
        });
    };

    const handleCloseTab = async () => {
        //delete template
        const callableReturnMessage = httpsCallable(functions, 'deleteMediaTemplate');
        callableReturnMessage({ templateId: templateId });

        if (window.opener) {
            // If there's an opener (website A), focus on it
            window.opener.focus();
        }

        // Close the current tab (website B)
        window.close();
    };




    if (!error)
        return (
            <><div className="upload-container">
                {albumDetails && (
                    <div className="album-details">
                        <h2>{albumDetails.name}</h2>
                        <p>Created: {new Date(albumDetails.creationTime).toLocaleString('en-GB')}</p>
                        <p>Price per photo: {(albumDetails.price.cents / 100).toFixed(2)} {albumDetails.price.currency}</p>
                    </div>
                )}
                <div
                    className="centered vertical-centered"
                    style={{ height: "100px", display: 'flex', flexDirection: 'column' }}
                >
                    <p>Upload your best shots from here. They will be processed and added to the album automatically.</p>
                    <p>Do not close or refresh this TAB until the upload is done.</p>
                    {/*  {isUploading && (
                        <div className="centered">
                            <h3>Uploading {files.length} pics</h3>
                        </div>
                    )} */}
                </div>

                {files.length === 0 && (
                    <>
                        <input
                            type="button"
                            value="Upload your photos"
                            className="centered"
                            disabled={albumId === null || templateId === null}
                            onClick={() => document.getElementById('selectedFile').click()} />
                        <input
                            id="selectedFile"
                            type="file"
                            accept="image/jpeg,image/png,image/jpg"
                            multiple
                            onChange={handleFileSelection}
                            style={{ display: 'none' }}
                            disabled={isUploading} />
                    </>
                )}

                {files.length > 0 && (
                    <div className="upload-stats">
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan="3">Progress: {Object.values(progress).length}/{files.length} files</td>
                                </tr>
                                <tr>
                                    <td>Sucessful: {Object.values(progress).filter(p => p === 100).length}</td>
                                    <td>Errors: {Object.values(progress).filter(p => p === -1).length}</td>
                                    <td>Duplicates: {Object.values(progress).filter(p => p === -2).length}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                <div className="file-list">
                    {files.map((file, index) => (
                        <div key={index} className="file-item">
                            <span className="file-name">{file.name}</span>
                            <div className="progress-container">
                                {(() => {
                                    switch (progress[index]) {
                                        case -2:
                                            return (
                                                <span className="duplicate-message" style={{ color: "#C8BC0E" }}>
                                                    Duplicate
                                                </span>
                                            );
                                        case -1:
                                            return (
                                                <button
                                                    onClick={() => handleRetry(index)}
                                                    className="retry-button"
                                                >
                                                    Retry
                                                </button>
                                            );
                                        default:
                                            return (
                                                <svg className="circular-progress" width="40" height="40" viewBox="0 0 40 40">
                                                    <circle
                                                        className="progress-background"
                                                        cx="20"
                                                        cy="20"
                                                        r="18"
                                                        fill="none"
                                                        stroke="#0E0B0B"
                                                        strokeWidth="3"
                                                    />
                                                    <circle
                                                        className="progress-bar"
                                                        cx="20"
                                                        cy="20"
                                                        r="18"
                                                        fill="none"
                                                        stroke={progress[index] === 100 ? "#327234" : "#51d7ef"}
                                                        strokeWidth="3"
                                                        strokeDasharray={`${2 * Math.PI * 18}`}
                                                        strokeDashoffset={`${2 * Math.PI * 18 * (1 - (progress[index] || 0) / 100)}`}
                                                        transform="rotate(-90 20 20)"
                                                    />
                                                    {progress[index] === 100 && (
                                                        <path
                                                            className="checkmark"
                                                            fill="none"
                                                            stroke="#327234"
                                                            strokeWidth="3"
                                                            d="M12,20 L18,26 L28,14"
                                                        />
                                                    )}
                                                </svg>
                                            );
                                    }
                                })()}
                            </div>
                        </div>
                    ))}
                </div>

            </div>



                {isDone && (
                    <div className="bottomBar" >
                        <button
                            onClick={handleCloseTab}
                            className="centered close-tab-button"
                        >
                            Done! Close tab</button>

                    </div>
                )}
                <div className="version-footer">
                    <p>v.{VERSION}</p>
                </div>
            </>
        );
    else return (
        //error
        <div className="upload-container">
            <h3 className='error'>Ops, there was an error...</h3>
            <p>Go back to the album and please try again.</p>
            <p className='error-desc'>{error}</p>
            <button
                onClick={handleCloseTab}
                className="centered close-tab-button"
            >Close tab</button>
            <div className="version-footer">
                <p>v.{VERSION}</p>
            </div>
        </div>
    );
};

const blobToBase64 = (blob) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
});



export default UploadPage;
